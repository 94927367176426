import React from 'react';
import Layout from '../components/layout';

import OurStoryFull from '../components/ourStoryFull';
import Contact from '../components/contact';
import Footer from '../components/footer';

const OurStoryPage = () => (
    <Layout>
        <OurStoryFull></OurStoryFull>
        <Contact></Contact>
        <Footer></Footer>
    </Layout>
)

export default OurStoryPage
