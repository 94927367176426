import React from 'react';

import contactStyles from "./contact.module.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import axios from 'axios';
import {toast} from 'react-toastify';

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      phoneNumber: "",
      email: "",
      comments: ""
    };
  }

  handleInputChange(event) {
    let {value, id} = event.target;

    let state = Object.assign({}, this.state);

    switch (id) {
      case 'name':
        state.name = value;
        break;
      case 'phoneNumber':
        state.phoneNumber = value;
        break;
      case 'email':
        state.email = value;
        break;
      case 'comments':
        state.comments = value;
        break;
      default:
        break;
    }

    this.setState(state);
  }

  async sendContactForm(event) {
    let {name, phoneNumber, email, comments} = this.state;
    let body = {
      name,
      phoneNumber,
      email,
      comments
    };

    let options = {
      method: 'post',
      url: 'https://lzy71prr86.execute-api.us-east-1.amazonaws.com/dev/sendContactForm',
      data: body
    }

    try {
      await axios(options);
      toast.success("Thanks for your email! We'll get back to you as soon as we can.", {position: toast.POSITION.TOP_CENTER});
      this.setState({name: "", phoneNumber: "", email: "", comments: ""});
    } catch (error) {
      toast.error("Oh No! We had some trouble sending your email. Feel free to give us a call at (5" +
          "87)-893-3646.", {position: toast.POSITION.TOP_CENTER});
    }
  }

  render() {
    return (
      <div id="contact" className={contactStyles.container}>
        <h1>Let's Talk</h1>
        <div className={contactStyles.formContainer}>
          <div className={contactStyles.inputGroup}>
            <label>Your Name</label>
            <input
              id="name"
              onChange={this
              .handleInputChange
              .bind(this)}
              value={this.state.name}></input>
          </div>
          <div className={contactStyles.inputGroup}>
            <label>Your Phone Number</label>
            <input
              id="phoneNumber"
              type="tel"
              value={this.state.phoneNumber}
              onChange={this
              .handleInputChange
              .bind(this)}></input>
          </div>
          <div className={contactStyles.inputGroup}>
            <label>Your Email</label>
            <input
              id="email"
              type="email"
              value={this.state.email}
              onChange={this
              .handleInputChange
              .bind(this)}></input>
          </div>
          <div className={contactStyles.inputGroup}>
            <label>Tell us a bit about what your looking for</label>
            <textarea
              rows="6"
              id="comments"
              value={this.state.comments}
              onChange={this
              .handleInputChange
              .bind(this)}></textarea>
          </div>
          <button onClick={this
            .sendContactForm
            .bind(this)}>Send the carrier pigeon&nbsp;
            <FontAwesomeIcon icon={["fas", "dove"]} flip="horizontal"/>
          </button>
        </div>
      </div>
    );
  }
}

export default Contact;
