import React from 'react';

import footerStyles from "./footer.module.css";

const Footer = () => (
  <div className={footerStyles.container}>
    <div className={footerStyles.contactContainer}>
      <div>
        <h2>EMAIL US</h2>
        <p>
          <a href="mailto:hello@homeandbliss.com">hello@homeandbliss.com</a>
        </p>
      </div>
      <div>
        <h2>GIVE US A CALL</h2>
        <p>
          <a href="tel:1-587-893-3646">(587)-893-3646</a>
        </p>
      </div>
    </div>
    <p>©{(new Date()).getFullYear()}
      &nbsp;Home and Bliss</p>
  </div>
)

export default Footer
