import React from 'react';

import logo from '../images/HomeandBlissRenoLogo.png';
import headerStyles from "./header.module.css";

const Header = () => (
  <div className={headerStyles.container}>
    <a href="/">
      <img
        src={logo}
        className={headerStyles.logo}
        alt={"Home and Bliss Renos Logo"}/>
    </a>
    <div className={headerStyles.linklist}>
      <a href="/renovations">Renovations</a>
      <a href="/ourstory">Our Story</a>
      <a href="/blog">Blog</a>
      {/* <a
        href="http://blog.homeandbliss.com"
        target="_blank"
        rel="noopener noreferrer">Blog</a> */}
      <a href="#contact" className={headerStyles.talkButton}>Let's Talk</a>
    </div>
  </div>
)

export default Header
