import React from 'react'

import styles from "./ourStoryFull.module.css";

import family from '../images/_DSC0319-min.jpg';

const OurStoryFull = () => (
    <div className={styles.container}>
        <h1>Our Story</h1>
        <div>
            <img src={family} alt="Sylvia and Matt" className={styles.mobileImg}/>
            <p>Hey! Nice to “e-meet” you. We’re Sylvia & Matt. What can we say? We love
                turning a house into a home. We purchased our first “fixer upper” in YYC in 2012
                and we haven’t been able to put down the hammer since then. Can’t stop. Won’t
                stop. Both of us have shared a love for houses ever since we got married back in
                2011.
                <br/>
                <br/>
                Throughout this past decade, we have remodeled three homes and added a son to
                our family. As a family we enjoy travelling, exploring our amazing YYC community
                and seeing the potential in Calgary homes. From foundation to finishes, we love
                the process of transforming a house into a home.
                <br/>
                <br/>
                We believe anything worth doing, is worth doing well especially when it comes to
                a home remodel. Our hope is that we can help renovate your space, add value to
                your home and inspire you by sharing our experiences.
                <br/>
                <br/>
                Connect with us today to learn more.
            </p>
            <img src={family} alt="Sylvia and Matt" className={styles.desktopImg}/>
        </div>
    </div>
)

export default OurStoryFull